import { Cart } from "../types/cart";
import dispatchEvent from "./dispatch-event";

export type CartChangeInputItem = {
  id?: `${number}:${string}`;
  quantity: number;
  selling_plan?: number;
  line?: number;
  properties?: object;
};

export type CartAddInputItem = {
  id: number;
  quantity: number;
  selling_plan?: number;
  line?: number;
  properties?: object;
};

export type CartInputData = {
  items: CartAddInputItem[];
};

const endpoints = {
  add: "/cart/add.js",
  change: `${window.Shopify.routes.root}cart/change.js`,
  get: "/cart.js",
};

const requestDefaults = {
  headers: {
    "Content-Type": "application/json",
  },
};

export async function addToCart(
  data: CartInputData,
  { element = document.body, eventOptions = {} } = {}
): Promise<Cart> {
  const add = await fetch(endpoints.add, {
    ...requestDefaults,
    method: "POST",
    body: JSON.stringify(data),
  });

  const response: Cart = await add.json();

  dispatchEvent("cart:add", element, eventOptions);
  dispatchEvent("cart:add", document, eventOptions);

  return response;
}

export async function changeQuantity(
  data: CartChangeInputItem,
  { element = document.body, eventOptions = {} } = {}
): Promise<Cart> {
  const change = await fetch(endpoints.change, {
    ...requestDefaults,
    method: "POST",
    body: JSON.stringify(data),
  });

  const response: Cart = await change.json();

  dispatchEvent("cart:add", element, eventOptions);
  dispatchEvent("cart:add", document, eventOptions);

  return response;
}

export function removeFromCart(
  id: CartChangeInputItem["id"],
  { element = document.body, eventOptions = {} } = {}
) {
  changeQuantity({ id, quantity: 0 }, { element, eventOptions });
}

export async function getCart(): Promise<Cart> {
  const cart = await fetch(endpoints.get, {
    method: "GET",
  });

  const response = await cart.json();

  return response;
}
